import { withRouter } from "react-router-dom";
import { FormControl, Grid, makeStyles } from "@material-ui/core";
import { usePermissions } from "../../../customHooks";
import { useTranslate } from "../../../customHooks";
import { SaveButton, toast } from "../../../components";
import { dataProvider } from "../../../dataProvider";
import { Button, Sheet, SheetBody } from "@panwds/react-ui";
import { Field, FormSpy } from "react-final-form";
import { CaretLeftIcon } from "@panwds/icons";
import { PANWDSForm, PANWDSInput } from "../../../components/PANWDSElements";
import { Button as PANWDSButton } from "@panwds/react-ui";
import { useCallback, useState } from "react";
import { Row } from "../../../components/FormElements";
import {
    composeValidators,
    requiredValidator,
    maxLength,
    isAlphaNumericWithUnderscore,
} from "../../../utils/validate";

const useStyles = makeStyles((theme) => {
    return {
        backButtonHeader: {
            display: "flex",
            marginBottom: "18px",
            alignItems: "center",
            padding: "16px",
        },
        backButtonTitle: {
            marginLeft: "12px",
            fontFamily: "Lato",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
            color: "#000000",
        },
        toolbar: {
            background: "transparent",
            display: "flex",
            gap: theme.spacing(1),
            justifyContent: "flex-end",
            padding: "10px 0",
            alignItems: "baseline",
        },
    };
});

const VpcGroupCreate = ({
    isOpenTags,
    setOpenTags,
    getManageVpcGroup,
}: any) => {
    const { permissions } = usePermissions();
    const translate = useTranslate();
    const classes = useStyles();
    const [submitting, setSubmitting] = useState(false);

    const goToManageVPCs = () => {
        getManageVpcGroup();
    };

    const save = useCallback((values) => {
        values["Name"] = values?.Name;
        setSubmitting(true);
        try {
            dataProvider.create("vpcGroups", { data: values })
            .then((response) => {
                toast.success(`Success: The VPC group ${values["Name"]} is created`);
                getManageVpcGroup();
                setSubmitting(false);
                return;
              })
            .catch((error: any) => {
                setSubmitting(false);
                toast.error( error?.error?.error, { toastId: "vpcgroup-create" });
            })
        } catch (error: any) {
            setSubmitting(false);
            toast.error(error?.error, { toastId: "vpcgroup-create" });
            return;
        }
    }, [history, permissions]);

    const CreateVpcGroupToolbar = (toolbarProps: any) => {
        const classes = useStyles();
        return (
            <div className={classes.toolbar}>
                <PANWDSButton
                    size="md"
                    appearance="secondary"
                    disabled={submitting}
                    onClick={() => {
                        getManageVpcGroup();
                    }}
                    dataMetrics="cloudngfw-vpcGroupName-create-cancel-button"
                >
                    Cancel
                </PANWDSButton>
                <SaveButton
                    appearance="primary"
                    size="md"
                    redirect={false}
                    submitOnEnter={true}
                    loading={submitting}
                    disabled={submitting}
                    dataMetrics="cloudngfw-vpcGroupName-create-save-button"
                    {...toolbarProps}
                />
            </div>
        );
    };

    const onCloseCreateSheet = () => {
        setOpenTags(false);
        getManageVpcGroup();
    };

    return (
        <>
            <Sheet
                onClose={onCloseCreateSheet}
                title={translate(
                    `resources.inventory.vpcGroups.createNewVPCGroup`
                )}
                isOpen={isOpenTags}
                showMask={true}
                position="md"
            >
                <SheetBody style={{ padding: 0 }}>
                    <div className={classes.backButtonHeader}>
                        <Button
                            aria-label="back button"
                            icon={<CaretLeftIcon size="sm" />}
                            appearance="secondary"
                            size="sm"
                            onClick={goToManageVPCs}
                        />
                        <span className={classes.backButtonTitle}>
                            {translate(`resources.inventory.tags.back`)}
                        </span>
                    </div>
                    <PANWDSForm
                        toolbar={<CreateVpcGroupToolbar />}
                        onSubmit={save}
                        requireStylingFooter={true}
                    >
                        <Row>
                            <FormControl fullWidth>
                                <Field
                                    name="Name"
                                    // @ts-ignore
                                    component={PANWDSInput}
                                    required
                                    validate={composeValidators(
                                        requiredValidator(
                                            translate("validation.required")
                                        ),
                                        maxLength(128),
                                        isAlphaNumericWithUnderscore
                                    )}
                                    title={translate(
                                        `resources.inventory.vpcGroups.vpcGroupName`
                                    )}
                                    dataMetrics="cloudngfw-vpcGroupName-create-field-name"
                                    disabled={submitting}
                                />
                            </FormControl>
                        </Row>
                        <Row>
                            <FormControl fullWidth>
                                <Field
                                    name="VpcGroupEntry.Description"
                                    // @ts-ignore
                                    component={PANWDSInput}
                                    title={translate(
                                        `resources.inventory.vpcGroups.description`
                                    )}
                                    validate={maxLength(512)}
                                    dataMetrics="cloudngfw-vpcGroupName-create-field-description"
                                    disabled={submitting}
                                />
                            </FormControl>
                        </Row>
                    </PANWDSForm>
                </SheetBody>
            </Sheet>
        </>
    );
};

export default withRouter(VpcGroupCreate);
