import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    loadingCenter: {
        position: "absolute",
        top: "50%",
        left: "50%",
        filter: "brightness(10%)",
        zIndex: 999
      }
}));

const CircularLoader = (props: any) => {
    const classes = useStyles();
    const { loading } = props;

    return (
        loading &&
            <div className={classes.loadingCenter}>
              <CircularProgress size={25} thickness={4} />
            </div>
    );
}

export default CircularLoader;
