import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import * as ReduxReducers from './slices';
import * as ReduxActions from './actions';
import ReduxResources from './utils/enums';

// Internal actions
import {setCspEnable, setPubSubMessageStatus} from "./slices/support";

const createRootReducer = (history: History<unknown>) => combineReducers({
    router: connectRouter(history),
    ruleStacks: ReduxReducers.ruleStacks,
    firewalls: ReduxReducers.firewalls,
    users: ReduxReducers.users,
    support: ReduxReducers.support,
    // rest of your reducers
});

const ReduxActionsWithInternalAction = {
    ...ReduxActions,
    setCspEnable,
    setPubSubMessageStatus
}

export {
    createRootReducer,
    ReduxResources,
    ReduxActionsWithInternalAction as ReduxActions,
    ReduxReducers
};
