
import { useEffect, useState } from 'react';
import { Box, FormControl, makeStyles } from '@material-ui/core';
import { nameStyleCursor } from '../../layout/styles';
import { usePermissions, useTranslate } from '../../customHooks';
import { flatPropertiesAndArrayValue, actionMapping } from './Utils/functions';
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalCloseButton, Bold, Body, Button as PANWDSButton } from '@panwds/react-ui';
import { Field, useForm } from 'react-final-form';
import { isEmpty } from 'lodash';
// @ts-ignore
import { OnChange } from 'react-final-form-listeners';
import { SaveButton, toast } from '../../components';
import { PANWDSForm, PANWDSSelectWithSearch, PANWDSTableLight } from '../../components/PANWDSElements';
import { dataProvider } from '../../dataProvider';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    background: "transparent",
    display: "flex",
    gap: theme.spacing(1),
    justifyContent: 'flex-end',
    padding: "0 16px 0 0",
    alignItems: 'baseline',
    marginTop: '0px',
    minHeight: 'initial !important',
  },
  backdrop: {
    position: "absolute",
    zIndex: 101,
    backgroundColor: "rgba(0, 0, 0, 0.15)",
  },
  cellStyles: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingRight: '20px',
  },
}));

export const RulePriorityEdit = ({ localGridData, globalPreGridData, globalPostGridData, scope, updateCallback }: any) => {
  const nameClass = nameStyleCursor();
  const [gridDataIndex, setGridDataIndex] = useState<any>([]);
  const [preGridDataIndex, setPreGridDataIndex] = useState<any>([]);
  const [postGridDataIndex, setPostGridDataIndex] = useState<any>([]);
  const [updateSetting, setUpdateSetting] = useState<any>(undefined);
  const [targetRule, setTargetRule] = useState(undefined);
  const translate = useTranslate();
  const { permissions } = usePermissions(); // TODO check if needed for edit priority mode
  const [saving, setSaving] = useState(false);

  const classes = useStyles();

  const extendIndexes = (indexes: [], data: [], setter: any) => {
    if (data.length > indexes.length) {
      const start = indexes.length;
      const end = data.length;
      const extended = [...Array(end - start).keys()].map(x => x + start);
      setter([...indexes, ...extended]);
    }
  };
  const createOrExtendIndexes = () => {
    if (scope === 'Local') {
      extendIndexes(gridDataIndex, localGridData, setGridDataIndex);
    } else {
      extendIndexes(preGridDataIndex, globalPreGridData, setPreGridDataIndex);
      extendIndexes(postGridDataIndex, globalPostGridData, setPostGridDataIndex);
    }
  };
  createOrExtendIndexes();

  const gridData = gridDataIndex.map((i: number, index: number) => ({ ...localGridData[i], Priority: localGridData[index].Priority }));
  const preGridData = preGridDataIndex.map((i: number, index: number) => ({ ...globalPreGridData[i], Priority: globalPreGridData[index].Priority }));
  const postGridData = postGridDataIndex.map((i: number, index: number) => ({ ...globalPostGridData[i], Priority: globalPostGridData[index].Priority }));

  const newDataIndex = {
    'local': gridDataIndex,
    'pre': preGridDataIndex,
    'post': postGridDataIndex,
  }

  const newDataMap = {
    'local': gridData,
    'pre': preGridData,
    'post': postGridData,
  };

  const setNewDataMap = {
    'local': setGridDataIndex,
    'pre': setPreGridDataIndex,
    'post': setPostGridDataIndex,
  };

  const localPrePostData = [...gridData, ...preGridData, ...postGridData];
  const currentLocalPrePostData: any[] = [...localGridData, ...globalPreGridData, ...globalPostGridData];

  const getListType = (RuleListName: string) => {
    if (RuleListName === 'LocalRule') {
      return 'local';
    } else if (RuleListName === 'PreRule') {
      return 'pre';
    } else {
      return 'post';
    }
  };

  const customSourceStyle = (cellProps: any) => {
    const { data } = cellProps;
    return data?.Rule?.NegateSource ? {
      textDecoration: 'line-through'
    } : {}
  };

  const columns: any[] = [
    {
      accessor: 'Priority',
      Header: translate(`resources.rules.fields.Priority`),
      columnSize: 0.75,
    },
    {
      id: 'RuleName',
      accessor: 'RuleName',
      Header: translate(`resources.rules.fields.RuleName`),
      columnSize: 2,
      Cell: ({row}: any) => row?.original?.RuleNameJSX
    },
    {
      accessor: 'Enabled',
      Header: translate(`resources.rules.fields.Enabled`),
      columnSize: 0.5,
    },
    {
      accessor: 'Source',
      Header: translate(`resources.rules.fields.Source`),
      columnSize: 2,
      style: customSourceStyle,
      Cell: ({row}: any) => row?.original?.SourceJSX,
    },
    {
      accessor: 'Destination',
      Header: translate(`resources.rules.fields.Destination`),
      columnSize: 2,
      Cell: ({row}: any) => row?.original?.DestinationJSX,
    },
    {
      accessor: 'Action',
      Header: translate(`resources.rules.fields.Action`),
      columnSize: 0.5,
    },
    {
      accessor: 'Constraints',
      Header: translate(`resources.rules.fields.Constraints`),
      columnSize: 2,
    },
    {
      accessor: 'Logging',
      Header: translate(`resources.rules.fields.Logging`),
      columnSize: 0.75,
    },
    {
      accessor: 'DecryptionRuleType',
      Header: translate(`resources.rules.fields.DecryptionRuleType`),
      columnSize: 1,
    },
  ];

  if (scope && scope !== 'Local') {
    columns.push({ accessor: 'RuleListName' })
  }

  const prepareData = (rowData: any) => {
    const buidConstratints = (data: any) => {
      const constraints = [];
      if (data.Rule?.Applications.length > 0) {
        constraints.push(`Application: ${data.Rule?.Applications.toString()}`);
      }
      if (!isEmpty(data.Rule?.Category)) {
        constraints.push(`URL Category: ${Object.values(data.Rule?.Category).join(", ")}`);
      }
      if (data.Rule?.Protocol) {
        constraints.push(`Protocol: ${data.Rule?.Protocol}`);
      } else if (data.Rule?.ProtPortList.length > 0) {
        constraints.push(`Protocol: ${data.Rule?.ProtPortList.join(", ")}`);
      }
      return <span title={decodeURI(constraints.join('%0A'))} className={classes.cellStyles}>{constraints.join(', ')}</span>;
    }

    const value = rowData.map((data: any) => ({
      RuleName: data.Rule?.RuleName,
      RuleStackName: data.RuleStackName,
      Priority: data.Priority,
      RuleNameJSX: (<span className={`${classes.cellStyles} ${nameClass.blueColor}`} title={data.Rule?.RuleName}>{data.Rule?.RuleName}</span>),
      Enabled: data.Rule?.Enabled ? translate(`generic.Yes`) : translate(`generic.No`),
      Source: flatPropertiesAndArrayValue(data.Rule?.Source),
      SourceJSX: (<span
        style={(data.Rule?.NegateSource) ? { textDecoration: "line-through" } : {}}
        title={decodeURI(flatPropertiesAndArrayValue(data.Rule?.Source, '%0A'))}
        className={classes.cellStyles}>
        {flatPropertiesAndArrayValue(data.Rule?.Source)}
      </span>),
      Destination: flatPropertiesAndArrayValue(data.Rule?.Destination),
      DestinationJSX: (<span
        style={(data.Rule?.NegateDestination) ? { textDecoration: "line-through" } : {}}
        title={decodeURI(flatPropertiesAndArrayValue(data.Rule?.Destination, '%0A'))}
        className={classes.cellStyles}>
        {flatPropertiesAndArrayValue(data.Rule?.Destination)}
      </span>),
      //@ts-ignore
      Action: actionMapping[data.Rule?.Action],
      Constraints: buidConstratints(data),
      Logging: data.Rule?.Logging ? translate(`generic.Yes`) : translate(`generic.No`),
      DecryptionRuleType: data.Rule?.DecryptionRuleType ?? 'None',
      RuleListName: data.RuleListName,
    }));

    return value;
  };

  const subtitle = scope === "Global" ? translate(`resources.rules.fields.GlobalListSubtitle`) : translate(`resources.rules.fields.LocalListSubtitle`);

  const movePriority = (ruleName: any) => {
    const listType = getListType(updateSetting.selected.RuleListName);
    const moveGridData = newDataMap[listType];
    let sourceIndex: number = 0, targetIndex: number = 0, direction = "";
    const move = updateSetting.position;
    let afterOffset = move === "after" ? 1 : 0;

    let editableIndex = [...newDataIndex[listType]];
    moveGridData.forEach((e: any, index: number) => {
      if (e.Priority === updateSetting.selected.Priority) {
        sourceIndex = index;
      }
      if (e.Rule.RuleName === ruleName) {
        targetIndex = index;
      }
    });
    direction = sourceIndex < targetIndex ? "down" : "up";
    if (sourceIndex === targetIndex) {
      return;
    }

    if (direction === "down") {
      editableIndex.splice(targetIndex + afterOffset, 0, editableIndex[sourceIndex]);
      editableIndex.splice(sourceIndex, 1);
    } else if (direction === "up") {
      editableIndex.splice(targetIndex + afterOffset, 0, editableIndex[sourceIndex]);
      editableIndex.splice(sourceIndex + 1, 1);
    }
    setNewDataMap[listType](editableIndex);
  };

  const toolbarActions: any[] = [{
    type: "button",
    title: "Move Before",
    action: (selected: any) => {
      if (selected.length && selected[0] && selected[0].Priority) {
        setUpdateSetting({ position: "before", selected: selected[0] });
      } else {
        toast.info("Please select the Rule you want to move.");
      }
    },
    appearance: 'secondary',
    disabled: saving,
  }, {
    type: "button",
    title: "Move After",
    action: (selected: any) => {
      if (selected.length && selected[0] && selected[0].Priority) {
        setUpdateSetting({ position: "after", selected: selected[0] });
      } else {
        toast.info("Please select the Rule you want to move.");
      }
    },
    appearance: 'secondary',
    disabled: saving,
  }];

  const ClearFieldComponent = ({ fieldName }: any) => {
    const form = useForm();
    useEffect(() => form.change(fieldName, undefined), []);

    return <></>;
  };

  const PANWDSMovePriorityModal = () => {
    const listType = getListType(updateSetting.selected?.RuleListName);
    const modalGridData = newDataMap[listType];
    const items = modalGridData.map((data: any) => ({
      text: `(Priority: ${data.Priority}) ${data.Rule.RuleName}`,
      value: data.Rule.RuleName,
      disabled: updateSetting.selected?.RuleName === data.Rule.RuleName
    }));

    return (<>
      <Modal
        size="sm"
        confirmClose={false}
        onClose={() => setUpdateSetting(undefined)}
        isOpen={updateSetting}
        style={{ top: "30%" }}
      >
        <ModalHeader title={`Move Rule ${updateSetting.selected?.RuleName}`} enableClose={false} />
        <ModalBody overflow>
          <Bold>
            {updateSetting.position === "before" && 'Before:'}
            {updateSetting.position === "after" && 'After:'}
          </Bold>
          <FormControl fullWidth style={{ marginTop: "10px" }}>
            <Field
              name="RuleNames"
              // @ts-ignore
              component={PANWDSSelectWithSearch}
              dataMetrics={`rules-list-move-${updateSetting.position}`}
              items={items}
            />
            <OnChange name="RuleNames">
              {(selected: any) => {
                setTargetRule(selected);
              }}
            </OnChange>
          </FormControl>
          <Body appearance="secondary">
            Current priority of {updateSetting.selected?.Rule?.RuleName} is {updateSetting.selected?.Priority}.
          </Body>
        </ModalBody>
        <ModalFooter>
          <ModalCloseButton>Cancel</ModalCloseButton>
          <PANWDSButton onClick={() => {
            movePriority(targetRule);
            setUpdateSetting(undefined);
          }} appearance="primary">Move</PANWDSButton>
        </ModalFooter>
      </Modal>
    </>);
  };

  const SaveToolbar = (toolbarProps: any) => {
    return (
      <div className={classes.toolbar}>
        <PANWDSButton
          size="md"
          appearance="secondary"
          disabled={saving}
          onClick={() => {
            setUpdateSetting(undefined);
            updateCallback('cancel');
          }}
        >
          Cancel
        </PANWDSButton>
        <SaveButton
          appearance="primary"
          size="md"
          loading={saving}
          submitOnEnter={true}
          disabled={!permissions?.CreateSecurityRule}
          dataMetrics="save-button-move-rules"
          {...toolbarProps}
        />
      </div>
    )
  };

  const save = async () => {
    // calculate priority diff
    setSaving(true);
    const priorityCurrentNewMap: any = {};
    const priorityNewCurrentMap: any = {};

    currentLocalPrePostData.forEach((d: any) => {
      const edittedPriority = localPrePostData
        .filter((e: any) => e.Rule.RuleName === d.Rule.RuleName)[0].Priority;
      if (d.Priority !== edittedPriority) {
        priorityCurrentNewMap[d.Priority] = edittedPriority.toString();
        priorityNewCurrentMap[edittedPriority] = d.Priority.toString();
      }
    });

    const currentPriorities: string[] = Object.keys(priorityCurrentNewMap);
    const newPriorities: string[] = Object.values(priorityCurrentNewMap);
    const updateRule: any = newPriorities.filter(p => currentPriorities.includes(p));

    // update calls
    await Promise.all(updateRule.map(async (newP: string) => {
      let currentP = parseInt(priorityNewCurrentMap[newP]);
      let values = currentLocalPrePostData.filter((d: any) => d.Priority === currentP)[0];
      const { RuleStackName, RuleListName, Rule } = values;
      let payload: any = {
        RuleEntry: Rule, RuleStackName: RuleStackName,
        RuleListName: RuleListName, Priority: parseInt(newP)
      };
      return dataProvider.update('rules', payload);
    }))
      .catch((error) => toast.error(error?.error))
      .finally(() => {
        setSaving(false);
        setUpdateSetting(undefined);
        updateCallback('save');
      });
  };

  return (
    <Box>
      <PANWDSForm
        toolbar={<SaveToolbar />}
        onSubmit={save}
      >
        <PANWDSTableLight
          columns={columns}
          data={prepareData(localPrePostData)}
          toolbarActionsArray={toolbarActions}
          title={translate(`resources.rules.name`)}
          subtitle={subtitle}
          offsetTableHeight={350}
          lockRows={saving}
          initialGroupBy={(scope && scope !== 'Local') ? ['RuleListName'] : []}
          searchFilterRequired
          emptyTitle={translate(`resources.rules.fields.EmptyTitle`)}
          emptySubtitle={translate(`resources.rules.fields.EmptySubtitle`)}
          isSingleSelect
          dataMetrics="rule-edit-priority-table"
          dataTestId="rule-edit-priority-table"
        />
        {!updateSetting && <ClearFieldComponent fieldName="RuleNames" />}
        {updateSetting && PANWDSMovePriorityModal()}
      </PANWDSForm>
    </Box>);
};

